import 'jquery';
import 'slick-carousel';
import './slick-carousel/slick/slick-theme.css';
import './slick-carousel/slick/slick.css';
import './slick-custom.css';
import './dropdowncheckbox/dropdowncheckbox.js';
import './dropdowncheckbox/dropdowncheckbox.css';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import './front.css';
import 'bootstrap/js/dist/modal';
import './selectize.bootstrap5.css';
import './selectize.min.js';
import './jquery.raty.css';
import './jquery.raty.js';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

$(() => {
  const event = new Event('atf6LoadComplete');
  document.dispatchEvent(event);
});

// Bootstrap custom required validation
(function () {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  var forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener(
      'submit',
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      },
      false
    );
  });
})();
